import React, { useState, useEffect } from 'react';
import { MainLayout } from '../layouts/main.layout';
import Seo from '../components/seo';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Script } from 'gatsby';

const MewsAndInsights = (props) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const location = props.location;

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://io.dropinblog.com/embedjs/5e03db61-a107-4fa4-9337-2f0a4760a919.js';
    scriptElement.async = true;
    scriptElement.onload = () => setScriptLoaded(true);

    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <Script src="https://io.dropinblog.com/embedjs/5e03db61-a107-4fa4-9337-2f0a4760a919.js"/>
        <div className="contactUsPageHolderWrapper">
          <h1 className="introBlogSlideContentTitle"></h1>
          <div className="contactUsContainer">
            <div id="dib-posts"></div>
            {scriptLoaded ? null : (
              <div className='loaderHolder'>
                <div className='loader-md' />
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

MewsAndInsights.defaultProps = {
  location: {},
};

export const query = graphql`
    query {
      allDataJson {
        edges {
          node {
            contactUs {
              button
              email
              firstName
              greeting
              lastName
              message
              phoneNumber
              sent
              title
            }
          }
        }
      }
      allTexts {
        edges {
          node {
            contactUs_button
            contactUs_email
            contactUs_firstName
            contactUs_greeting
            contactUs_lastName
            contactUs_message
            contactUs_phoneNumber
            contactUs_sent
            contactUs_title
          }
        }
      }
    }
`;

export default MewsAndInsights;

export const Head = () => (
  <Seo title="UploadLetters.com - News & Insights" description="Discover why businesses trust UploadLetters.com for Certified Mail, Priority Mail, and First-Class mailing and tracking services. Contact us for secure online print and mail. Mail letters online." />
);
